<template>
  <div class="container">
    <div class="card border-0 shadow-sm">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col my-auto">
            <h2 class="text-primary fw-bolder mb-0">Add domain</h2>
          </div>
          <div
            class="
              col-auto
              my-auto
              ms-auto
              d-flex
              flex-column
              justify-content-center
              align-center
            "
          >
            <router-link to="/domains/" class="btn btn-light text-dark">
              <i class="far fa-arrow-left me-1 d-none d-md-inline-block" />Back
            </router-link>
          </div>
        </div>
        <div class="alert alert-dark mb-4" role="alert">
          Add your domain to Swandoola to enable the marketing suite and bulk
          email functionality.
        </div>
        <div class="row" v-if="!error">
          <div class="col-md-6">
            <input
              type="text"
              v-model="domain"
              class="form-control"
              placeholder="example.com"
            />
            <small class="text-pink" v-if="!isValidDomain"
              >Please enter a valid domain</small
            >
          </div>
          <div class="col-auto">
            <button
              class="btn btn-primary"
              :disabled="!isValidDomain"
              @click="addDomain"
            >
              <i class="far fa-check me-2"></i>Add domain
            </button>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col">
            <h4 class="fw-bold">
              {{ error }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      domain: "",
      error: "",
    };
  },
  computed: {
    isValidDomain() {
      var re = new RegExp(
        /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
      );
      return this.domain.match(re);
    },
  },
  methods: {
    addDomain() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/domains", { domain: this.domain })
        .then(({ data }) => {
          this.domain = "";
          if (data.added) {
            this.$router.push("/domains");
          } else {
            this.error = data.message;
          }
        });
    },
  },
};
</script>

<style>
</style>
